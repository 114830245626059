export const SidebarData = [
  {
    value: '/',
    label: 'ACCUEIL'
  },
  {
    value: '/our-story',
    label: 'NOTRE HISTOIRE'
  },
  {
    value: '/programme',
    label: 'PROGRAMME'
  },
  {
    value: '/infos-pratiques',
    label: 'INFOS PRATIQUES'
  },
  {
    value: '/RSVP',
    label: 'RSVP'
  },
  {
    value: '/contacts',
    label: 'CONTACTS'
  }
];
