import React from 'react';
import './styles.scss';

const Resume = () => {
  return (
    <div className="container-resume">
      <div className="box resume-text-container">
        <div className="title">Bienvenue sur le site du mariage</div>
        <p>Cher tous, chères toutes,</p>
        <p>
          Nous sommes ravis et honorés de vous embarquer avec nous dans cette
          aventure unique, pour nous, déjà, mais aussi pour vous, on
          l&apos;espère! Le départ en Inde et les festivités approchent à grands
          pas...
        </p>
        <p>Alors, prêt(es)? </p>
        <p>
          Vous trouverez ici toutes les informations utiles pour le bon
          déroulement de votre séjour et de votre venue. Notre histoire, le
          programme de l&apos;évenement, quelques tips et contacts sur place...
          Tout y est!
        </p>
        <p>
          Maintenant, nous avons tout simplement hâte de vous retrouver à Pondy!
        </p>
        <p>A très vite, </p>
        <p> Peace & Love, Forever</p>
      </div>
    </div>
  );
};

export default Resume;
