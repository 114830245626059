import React from 'react';
import TimelineSchedule from '../../components/timelineSchedule';

const SchedulePage = () => {
  return (
    <div className="margin130">
      <h2 className="title-page nm">Programme</h2>
      <TimelineSchedule />
    </div>
  );
};

export default SchedulePage;
